import Glide from "@glidejs/glide"

$( document ).on( "ready turbolinks:load", function() {
	
	$( "button[data-target=\"#navigation\"]" ).click( function() {
		$( "div#navigation" ).toggleClass( "hidden" );
	} );
	
	$( "li[data-type=\"portfolio_tab_switcher\"]" ).click( function() {
		let li = $( this );
		if ( !li.hasClass( "border-blue-400" ) ) {
			$( "li[data-type=\"portfolio_tab_switcher\"]" ).removeClass( "border-blue-400" );
			li.addClass( "border-blue-400" );
			$( "div[data-type=\"portfolio_tab\"]" ).addClass( "hidden" );
			$( "div[data-type=\"portfolio_tab\"]" + li.data( "target" ) ).removeClass( "hidden" );
			let glides = document.querySelectorAll( "div[data-type=\"portfolio_tab\"]:not(.hidden) .glide" );
			for ( let i = 0; i < glides.length; i++ ) {
				new Glide( glides[ i ] ).mount();
			}
		}
	} );
	
	$( "li[data-type=\"services_tab_switcher\"]" ).click( function() {
		let li = $( this );
		if ( !li.hasClass( "border-blue-400" ) ) {
			$( "li[data-type=\"services_tab_switcher\"]" ).removeClass( "border-blue-400" );
			li.addClass( "border-blue-400" );
			$( "div[data-type=\"services_tab\"]" ).addClass( "hidden" );
			$( "div[data-type=\"services_tab\"]" + li.data( "target" ) ).removeClass( "hidden" );
		}
	} );
	
	$( "button[data-type=\"get_estimate_button\"]" ).click( function() {
		$( "html, body" ).animate( { scrollTop: $( "#" + $( this ).data( "scroll" ) ).offset().top }, 1000 );
	} );
	
	$( document ).on( "ajax:beforeSend", "#contact_form", function() {
		$( "#contact_form input, #contact_form select, #contact_form textarea, #contact_form button" ).prop( "disabled", true );
	  });
	$( "#contact_form" ).on( "ajax:success", function( e ) {
		$( "#contact_form input[type=\"text\"]" ).val( "" );
		$( "#contact_form input[type=\"email\"]" ).val( "" );
		$( "#contact_form select#real_estate" ).val( $( "#contact_form select#real_estate option:first" ).val() );
		$( "#contact_form select#services" ).val( $( "#contact_form select#services option:first" ).val() );
		$( "#contact_form textarea" ).val( "" );
		$( "#contact_form input[type=\"checkbox\"]" ).prop( "checked", false );
		$( "#contact_form input, #contact_form select, #contact_form textarea, #contact_form button" ).prop( "disabled", false );
		$( "#contact_form_answer" ).text( e.originalEvent.detail[ 2 ].responseText );
		$( "#contact_form_error" ).addClass( "hidden" );
	} ).on( "ajax:error", function( e ) {
		$( "#contact_form input, #contact_form select, #contact_form textarea, #contact_form button" ).prop( "disabled", false );
		$( "#contact_form_error" ).removeClass( "hidden" );
	} );
	
	let glides = document.querySelectorAll( "div[data-type=\"portfolio_tab\"]:not(.hidden) .glide" );
	for ( let i = 0; i < glides.length; i++ ) {
		new Glide( glides[ i ] ).mount();
	}
	
	$( document ).scrollTop( 0 );
	
} );